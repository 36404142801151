$('#search_form').on('submit', function(e){
	e.preventDefault();
	if($('#search_value').val() == undefined || $('#search_value').val().trim() == ''){
		$('#search_value').val($('#search_label').val());
	}
	window.location.href = '/search/' + $('#search_value').serialize().replace($('#search_value').attr('name')+'=', '');
	return false;
});

$('.spell_add_form').on('submit', function(e){
	e.preventDefault();
	if($(this).find('input[name="spell_id"]').val() != undefined && $(this).find('input[name="spell_id"]').val().trim() != ''){
		$.post('/tomes/' + $(this).find('input[name="tome_id"]').val() + '/spell/' + $(this).find('input[name="spell_id"]').val(), {
			_token : $(this).find('input[name="_token"]').val(),
			_method : $(this).find('input[name="_method"]')?$(this).find('input[name="_method"]').val():'post',
		})
		.done(function() {
			location.reload();
		})
		.fail(function() {
			alert( "error" );
		});
	}
	return false;
});

$('.tome_remove_form').on('submit', function(e){
	e.preventDefault();

	var r = confirm("Are you sure you want to delete this tome?");
	if (r == true) {
		$.post('/tomes/' + $(this).find('input[name="tome_id"]').val(), {
			_token : $(this).find('input[name="_token"]').val(),
			_method : 'delete',
		})
		.done(function() {
			window.location.href = '/tomes';
		})
		.fail(function() {
			alert( "error" );
		});
	}

	return false;
});

$("#search_label").autocomplete({
	source: searchSource,
	select: function( event, ui ){
		window.location.href = '/tomes/' + ui.item.val;
	}
});

$("#spell_id_label").autocomplete({
	source: spellSource,
	select: function( event, ui ){
		$('#spell_id_label').val(ui.item.label);
		$('#spell_id_value').val(ui.item.val);
		$('#spell_add_form').submit();
	}
});

$('.filters input[type="checkbox"]').on('change', function(){
	var element = $(this);
	var data = element.attr('name');
	var value = element.val();
	var show = element.is(':checked');
	if(value == 'all'){
		// $('.filters input[type="checkbox"]').prop('checked', show);
		$('.filters input[type="checkbox"]').each(function(){
			if($(this).val() != 'all'){
				$(this).prop('checked', !show).trigger('click');
			}
		});
	} else {
		$('li.list-item').each(function(){
			if($(this).data(data) == value){
				if(show){
					$(this).addClass('show').removeClass('hidden');
				} else {
					$(this).addClass('hidden').removeClass('show');
				}
			}
		});
		$('ul.list-items').each(function(){
			if($(this).find('li.list-item.show').length > 0){
				$(this).prev('h4').addClass('show').removeClass('hidden');
				$(this).addClass('show').removeClass('hidden');
			} else {
				$(this).prev('h4').addClass('hidden').removeClass('show');
				$(this).addClass('hidden').removeClass('show');
			}
		});
	}
	$(this).parents('.panel').find('i:first').html($(this).parents('.panel').find('li.list-item.show').length+' spells');
});

// $('.tome-add-spell-tome-selection').popover({
// 	content:
// });
